import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import './App.css';

import Home from './pages/Home.jsx';
import About from './pages/About.jsx';
import Projects from './pages/Projects.jsx';
import Datenschutz from './pages/Datenschutz.jsx';
import Impressum from './pages/Impressum.jsx';

class App extends Component {
  render() {
    return(
      <Router>
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/datenschutz" component={Datenschutz} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/projects" component={Projects} />
        </div>
      </Router>
    );
  };
};

export default App;
