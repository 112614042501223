import React, { Component } from "react";
import { Link } from "react-router-dom";

import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import Jumbotron from '../components/Jumbotron.jsx'

class Projects extends Component{
  render(){
    return(
      <div>
        <Navbar />
        <Jumbotron />
        <div className="container">
          <h1>Projects</h1><br />
          <p>Sadly <Link to="/">this</Link> is my only project - yet.</p>
        </div>
        <Footer />
      </div>
    );
  };
}

export default Projects;
