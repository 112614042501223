import React, { Component } from "react";

import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import Jumbotron from '../components/Jumbotron.jsx'

class About extends Component{
  render(){
    return(
      <div>
        <Navbar />
        <Jumbotron />
        <div className="container">
          <h1>About Me</h1><br />
          <h3>Life</h3>
          <p>I am Christian, a Software Developer focusing on backend applications.</p>
          <p>After studying mathematics for nearly five years I realised, that I want to do something more applied than proving theorems like the <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Carathéodory%27s_existence_theorem"> Carathéodory Theorem </a>. So I decided to get a job in the field of information technology. Given the chance of being a trainee I was able to experience many different aspects of IT for instance business intelligence or IT Finance. Nonetheless I was so fascinated about coding and software development, that I want to become a software developer, which I now am.</p>
          <h3>Interests as a developer</h3>
          <ul>
            <li>Java frameworks especially Spring Boot</li>
            <li>Testing Frameworks like <a target="_blank" rel="noopener noreferrer" href="http://spockframework.org/">Spock</a>, <a target="_blank" rel="noopener noreferrer" href="https://cucumber.io/">Cucumber</a> or <a target="_blank" rel="noopener noreferrer" href="https://github.com/intuit/karate">Karate</a></li>
            <li>Data Analysis and Machine Learning using Python</li>
          </ul>
          <h3>Interests as a non-developer</h3>
          <ul>
            <li>jogging and physical exercising</li>
            <li>football/soccer most importantly the Hamburger Sport-Verein HSV</li>
            <li>social contacts <span role="img" aria-label="winking face">&#x1F609;</span></li>
          </ul>
        </div>
        <Footer />
      </div>
    );
  };
}

export default About;
