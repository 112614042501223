import React, { Component } from "react";
import { Link } from "react-router-dom";

import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import Jumbotron from '../components/Jumbotron.jsx'

class Home extends Component{
  render(){
    return(
      <div>
        <Navbar />
        <Jumbotron />
        <div className="container">
          <h1>Hello and Welcome!</h1><br />
          <p>This is my own little website, where I want to publish little projects and try out things, I want to try.</p>
          <p>Therefore do not expect perfection in every inch. </p>
          <p>If you want to know, who I am, take a look at my <Link to="/about">About Me</Link> page.</p>
          <br />
          </div>
        <Footer />
      </div>
    );
  };
}

export default Home;
