import React, { Component } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import './Navbar.css';

class Navbar extends Component{
  render(){
    return(
      <nav className="navbar navbar-expand-md navbar-dark bg-dark mb-4">
        <div className="container">
          <Link className="navbar-brand" to="/">Home</Link>
          <div>
            <Dropdown navbar="true">
              <Dropdown.Toggle variant="Succes" id="dropdown-basic">
                <span className="navbar-toggler-icon"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/about">About Me</Dropdown.Item>
                <Dropdown.Item href="/projects">Projects</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
         {/*    <div>
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/about">About Me</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/projects">Projects</Link>
              </li>
            </ul> 
          </div>*/}
        </div>
      </nav>
    );
  };
};

export default Navbar;
