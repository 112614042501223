import React, { Component } from "react";
import { Link } from "react-router-dom";
import './Footer.css';

class Footer extends Component{
  render(){
    return(
      <footer className="footer-copyright text-center py-3">
        <div className="container">
        <hr />
        <p><Link className="footer-link" to="/impressum">Impressum</Link> | <Link className="footer-link" to="/datenschutz">Datenschutz</Link></p>
            <Link className="footer-link" to="/">developer-behrens.com</Link> &#169; {new Date().getFullYear()}
        </div>
      </footer>
    );
  };
};

export default Footer;
