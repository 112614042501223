import React, { Component } from "react";
import './Jumbotron.css';

class Jumbotron extends Component {
  render(){
    return(
      <div className='jumbotron'>
        <div className='container'>
        </div>
      </div>
    )
  }
}

export default Jumbotron
